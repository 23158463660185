import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validator } from "web3-validator";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../utils/axiosInstance";
import { toast } from "react-toastify";
import { copyToClipboard } from "../utils/copyToClipboard";

const MaticWalletAddress = () => {
  const [maticAddress, setMaticAddress] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const PMBAddress = "0x8217F87c71834C50CF18bA68cB5876e127b6CC6d";
  const PMEAddress = "0x3b7A389DF11A0F71739b1632dcAaf89D2E6c3116";

  const isValidMaticAddress = (address) => {
    try {
      validator.validate(["address"], [address]);
      if (address === PMBAddress || address === PMEAddress) {
        setErrMsg(
          address === PMBAddress
            ? "PMB address is not acceptable"
            : "PME address is not acceptable"
        );
        return false;
      } else return true;
    } catch (error) {
      return false;
    }
  };
  const handleMaticAddressChange = (event) => {
    const { value } = event.target;
    setMaticAddress(value);
    setIsValid(isValidMaticAddress(value));
  };
  const handleSwap = () => {
    setIsLoading(true);
    api
      .post("generate-address/", { matic_address: maticAddress })
      .then((res) => {
        toast.success("your Tron address generated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          // transition: Bounce,
        });
        const response = res.data.tron_public_address;
        navigate(`/qrcode/${response}`);
        sessionStorage.setItem("tronWallet", response);
        sessionStorage.setItem("maticWallet", maticAddress);
      })
      .catch(() =>
        toast.error("something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          // transition: Bounce,
        })
      )
      .finally(() => setIsLoading(false));
  };
  function onChange(value) {
    setCaptchaValue(value);
  }
  return (
    <div className="container">
      <div className="box">
        <div className="title">Pcoin to PMB Swap</div>
        <div className="info">
          Enter your Polygon / Matic address to get PMB
        </div>
        <div className="input-box">
          <input
            type="text"
            value={maticAddress}
            onChange={handleMaticAddressChange}
            className={`input ${
              isValid !== null ? (isValid ? "is-valid" : "is-invalid") : ""
            }`}
          />
          {isValid !== null && (
            <p className={`input-msg ${isValid ? "is-valid" : "is-invalid"}`}>
              {isValid
                ? "Valid Matic Address"
                : errMsg ?? "Invalid Matic Address"}
            </p>
          )}
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onChange}
        />{" "}
        <div className="pmb-address">
          <h3>Please Add PMB Smart Contract To Your Wallet:</h3>
          <div
            className="QR-card-input"
            onClick={() => copyToClipboard(PMBAddress)}
          >
            <input type="text" value={PMBAddress} readOnly />
            <button>copy</button>
          </div>
        </div>
        <button
          onClick={handleSwap}
          disabled={!isValid || !captchaValue}
          className="primary-button wallet-btn"
        >
          {isLoading ? <div className="loader" /> : "Swap PMB"}
        </button>
      </div>
    </div>
  );
};

export default MaticWalletAddress;
