import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import Img from "./assets/images/swap.png";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="App">
      <img src={Img} alt="" className="banner" />
      <Outlet />
      <ToastContainer />
    </div>
  );
};

export default App;
