import React, { useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useParams, useNavigate } from "react-router-dom";
import TransactionTable from "../components/TransactionTable";
import { copyToClipboard } from "../utils/copyToClipboard";

function QRCode() {
  const { tronAddress } = useParams();
  const navigate = useNavigate();
  const tronWallet = sessionStorage.getItem("tronWallet");
  const maticWallet = sessionStorage.getItem("maticWallet");

  useEffect(() => {
    if (tronWallet !== tronAddress) {
      navigate("/");
    }
  }, [tronWallet, tronAddress]);
  return (
    <div className="">
      <div className="container">
        <div className="box">
          <div className="info">
            To proceed with your PCOIN to PMB swap, please scan the QR code
            below to send your PCOIN's to below address to get PMB on your
            Polygon/Matic address by 1:25 rate.
          </div>
          <QRCodeSVG value={tronAddress} className="QR-code-svg" />
          <div
            className="QR-card-input"
            onClick={() => copyToClipboard(tronAddress)}
          >
            <input type="text" value={tronAddress} readOnly />
            <button>copy</button>
          </div>
          <div className="pmb-address">
            <h3>Your Matic Wallet Address</h3>
            <div className="QR-card-input">
              <input type="text" value={maticWallet} readOnly />
            </div>
          </div>
        </div>
      </div>

      <div className="table-container">
        <TransactionTable />
      </div>
    </div>
  );
}

export default QRCode;
