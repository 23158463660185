import axios from "axios";

// Create an instance of Axios with default configuration
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 10000, // Request timeout
  headers: {
    "Content-Type": "application/json",
  },
});
