import React, { useState, useEffect, useRef } from "react";
import { api } from "../utils/axiosInstance";
import { toast } from "react-toastify";
import { formatDate } from "../utils/date";
import { Link } from "react-router-dom";
const TransactionTable = () => {
  const maticWallet = sessionStorage.getItem("maticWallet");

  const [data, setData] = useState(null);
  const intervalRef = useRef(null); // useRef to store interval ID
  console.log(data);
  useEffect(() => {
    const fetchData = async () => {
      await api
        .post("user-transactions/", { matic_address: maticWallet })
        .then((res) => {
          setData(res.data);
        })
        .catch(() =>
          toast.error("something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            // transition: Bounce,
          })
        );
    };

    fetchData();

    // Set up the interval using useRef
    intervalRef.current = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array to run only on initial render

  return (
    <div className="transaction-table">
      <h3>Transactions</h3>
      <div className="table-responsive">
        <table className="table table-hover text-nowrap table-bordered">
          <thead>
            <tr>
              <th scope="col">PCOIN Amount</th>
              <th scope="col">PMB Amount</th>
              <th scope="col">Date</th>
              <th scope="col">TX ID</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.pcoin_amount}</td>
                  <td>{item.pmb_amount}</td>
                  <td>{formatDate(item.created_at)}</td>
                  <td>
                    {item.pmb_tx_hash ? (
                      <a
                        href={`https://polygonscan.com/tx/${item.pmb_tx_hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TX ID
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </td>
                  <td>
                    <div className={`status ${item.status}`}>{item.status}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <div>No Data</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionTable;
